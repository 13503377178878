/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@mui/material"
import { Download } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useQuery } from "react-query"
import html2canvas from "html2canvas"
import { DateTime } from "luxon"
import jsPDF from "jspdf"

import logo from "../../Assets/RIDE_logo.png"
import RecieptInfo from "./receiptInfo.jsx"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import { splitNumberAndName } from "../../Utils/dataFormat.js"

function DownloadReceipt() {
    const { receiptId } = useParams()
    const { sm, md, xs, lg } = useBreakPoints()
    const receiptRef = useRef({
        style: {
            transform: lg
                ? "scale(1)"
                : md
                ? "scale(0.8)"
                : sm
                ? "scale(0.6)"
                : xs
                ? "scale(0.45)"
                : "scale(1)",
        },
    })
    const buttonRef = useRef({
        style: { transform: "scale(1)" },
    })
    const [isDownloading, setIsDownloading] = useState(false)

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { data } = useQuery(
        ["receips"],
        () => axiosPrivate.get(`receipt/${receiptId}`),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )
    const paymentInfo = useMemo(
        () => [
            {
                label: "Invoice No.",
                value: data?.data?.data?.receipt_number
                    ?.toString()
                    .padStart(8, "0"),
            },
            {
                label: "Customer Name",
                value:
                    splitNumberAndName(data?.data?.data?.service_number)
                        ?.name ?? data?.data?.data?.full_name,
            },
            {
                label: "Buyer's TIN",
                value:
                    splitNumberAndName(data?.data?.data?.service_number)?.tin ??
                    "Invalid TIN",
            },
        ],
        [data?.data?.data]
    )
    const handleDownloadPDF = async () => {
        setIsDownloading(true)
        const receiptContainer = receiptRef.current
        const buttonElement = buttonRef.current

        // Store the original display value of the button
        const originalDisplay = buttonElement.style.display

        // Hide the button temporarily
        buttonElement.style.display = "none"

        const canvas = await html2canvas(receiptContainer, {
            allowTaint: true,
            useCORS: true,
            scale: window.devicePixelRatio,
        })

        // Restore the button's display
        buttonElement.style.display = originalDisplay

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4")

        // Calculate the ratio to fit the content within the A4 dimensions
        const scaleFactor = 210 / canvas.width

        // Add the captured content to the PDF
        pdf.addImage(
            canvas,
            "PNG",
            0,
            0,
            canvas.width * scaleFactor,
            canvas.height * scaleFactor
        )

        // Save the PDF
        pdf.save(`receipt_${data?.data?.data?.receipt_number}`)
        setIsDownloading(false)
    }

    // Adjust zoom level when component mounts or screen size changes
    useEffect(() => {
        const zoomedContainer = receiptRef.current
        const handleResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(0.8)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(0.6)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(0.45)"
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [xs, sm, md, lg, receiptRef])
    useEffect(() => {
        const zoomedContainer = buttonRef.current
        const handleBtnResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(1.1)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(1.2)"
            }
        }

        handleBtnResize()
        window.addEventListener("resize", handleBtnResize)
        return () => window.removeEventListener("resize", handleBtnResize)
    }, [xs, sm, md, lg, buttonRef])

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                overflowX: "auto",
                padding: 1,
            }}
        >
            <div
                id="container"
                style={{
                    transformOrigin: "top center",
                }}
                ref={receiptRef}
            >
                <Box
                    sx={{
                        border: 2,
                        borderColor: "#fdbc3d",
                        bgcolor: "#fff",
                        p: 2,
                        m: xs ? "2em" : "0.5em",
                        position: "relative",
                        minWidth: "210mm", // A4 width
                        minHeight: "297mm", // A4 height
                    }}
                    id="a4-layout-container"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: 120,
                                            height: 70,
                                            overflow: "hidden",
                                            cursor: "pointer",
                                            bgcolor: "#fff",
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                bgcolor: "transparent",
                                            }}
                                            variant="square"
                                        >
                                            <img
                                                src={logo}
                                                alt="Avatar"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </Avatar>
                                    </Box>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                        fontWeight="bold"
                                        textAlign="center"
                                    >
                                        HYBRID DESIGNS PLC
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="body2" gutterBottom>
                                        <strong>TIN: </strong>0021283469
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        S/C.KIRKOS.W-02.H.NO.202/5TH FLOOR
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        LEBENZE BUILDING, GAZEBO SQUARE
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        ADDIS ABABA, ETHIOPIA
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Tel: </strong>011-657-4866
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Short Code: </strong>8812
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Typography variant="body2" gutterBottom ml={1}>
                            <strong>Date: </strong>
                            {DateTime.fromISO(
                                data?.data?.data?.created_at
                            ).toFormat("dd/MM/yyyy HH:mm")}
                        </Typography>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Divider color="#fdbc3d" />
                            <Typography
                                variant="body2"
                                gutterBottom
                                textAlign="center"
                                fontWeight={900}
                                sx={{
                                    my: 1,
                                }}
                            >
                                Cash Invoice
                            </Typography>
                            <Divider color="#fdbc3d" sx={{ mb: 1 }} />
                            {paymentInfo?.map((info) => (
                                <Box key={info.label} sx={{ display: "flex" }}>
                                    <Box width="50%">
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {info.label}
                                        </Typography>
                                    </Box>
                                    <Box width="50%">
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {info.value}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RecieptInfo
                                description="Commission"
                                quantity={1}
                                amount={Number(data?.data?.data?.amount)}
                                vat={Number(data?.data?.data?.vat)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    id="download-button"
                    ref={buttonRef}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                        "@media print": {
                            display: "none",
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{
                            bgcolor: "#fdbc3d",
                            color: "black",
                            ":hover": { bgcolor: "#fdbc3d" },
                            borderRadius: 5,
                            px: 2,
                            py: 1,
                            gap: 0.5,
                            width: 250,
                        }}
                        disabled={isDownloading}
                        onClick={handleDownloadPDF}
                    >
                        <Typography
                            variant="body2"
                            fontWeight={900}
                            textAlign="center"
                        >
                            Download Receipt
                        </Typography>
                        <Download />
                    </IconButton>
                </Box>
            </div>
        </Box>
    )
}

export default DownloadReceipt
